:root {
  /* Dark Mode */
  --black-color-bg: #0d1117;
  --gray-color: #161b22;
  --purple-color: #acb4fe;
  --color-bg-variant: #2c2c6c;
  --color-primary-variant: rgba(77, 181, 255, 0.4);
  --purple-smoke-color: #cbbee7;
  --white-smoke-color: #8b949e;
  --white-color: #e5e5e5;
  --gradient-color: linear-gradient(99.51deg, #cbbee7 9.03%, #ffffff 89.53%);

  /* Light Mode */
  --white-color-bg: white;
  --white-color-smoke-bg: #cdd0f3;
  --purple-dark-color: #1c1b20;
  --black-color: #212121;
  --white-gradient-color: linear-gradient(
    99.51deg,
    #acb4fe 9.03%,
    #ae88fe 89.53%
  );
}
* {
  margin: 0;
  box-sizing: border-box;
  list-style: none;
}
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  list-style: none;
}

.dark-mode {
  transition: 150ms;
  background-color: var(--black-color-bg);
}

.white-mode {
  transition: 150ms;
  background-color: var(--white-color-smoke-bg);
}

.dark-mode .hello,
.skills,
h1,
h2,
h4,
h5,
h6,
.h1_Project,
small {
  color: var(--white-color-bg);
}
.dark-mode h3 {
  color: var(--white-color-bg);
}
.dark-mode .text-center {
  color: var(--black-color-bg);
}
.dark-mode .show {
  background-color: var(--black-color-bg);
  color: var(--white-color-bg);
}
.dark-mode .project_view_container {
  background: var(--white-color-bg);
}
.dark-mode label {
  color: var(--black-color-bg);
}
.dark-mode .container {
  background-color: var(--gray-color);
}
.dark-mode .container input {
  background-color: var(--gray-color);
  color: var(--white-color-bg);
}

.dark-mode .container textarea {
  background-color: var(--gray-color);
  color: var(--white-color-bg);
}
.dark-mode .container label {
  color: var(--white-color-bg);
}
.dark-mode .container h3 {
  color: var(--white-color-bg);
}
.dark-mode .project_view_container div {
  color: var(--black-color-bg);
}
.dark-mode .project_view_body div {
  color: var(--black-color-bg);
}
.dark-mode .hr {
  background-color: var(--white-color-smoke-bg);
}
.dark-mode .dialogDocs {
  background-color: var(--white-color-smoke-bg);
  color: #00f2fe;
}
.dark-mode .p_Lang {
  color: var(--black-color-bg);
}

.white-mode label {
  color: var(--black-color-bg);
}
.white-mode .hello {
  color: var(--black-color-bg);
}
.white-mode .myText {
  color: var(--black-color-bg);
}
.white-mode .myText1 {
  color: var(--black-color-bg);
}
.white-mode .myText2 {
  color: var(--black-color-bg);
}
.white-mode.dialogDocs {
  background-color: var(--white-color-smoke-bg);
}
.white-mode .h1_Project {
  color: var(--black-color-bg);
}
.white-mode h1 {
  color: var(--black-color-bg);
}
.white-mode h2 {
  color: var(--black-color-bg);
}
.white-mode h3 {
  color: var(--black-color-bg);
}
.white-mode h4 {
  color: var(--black-color-bg);
}
.white-mode h6 {
  color: var(--black-color-bg);
}
.white-mode strong {
  color: var(--black-color-bg);
}
.white-mode .lookingStrong {
  color: var(--white-color-bg);
}
.white-mode p {
  color: var(--black-color-bg);
}
.white-mode small {
  color: var(--black-color-bg);
}

.white-mode .fullstack {
  color: var(--white-color-bg);
}
.white-mode a {
  color: var(--purple-dark-color);
}
/* 
  --purple-color: #acb4fe;
  --color-bg-variant: #2c2c6c;
  --color-primary-variant: rgba(77, 181, 255, 0.4);
  --purple-smoke-color: #cbbee7;
  --white-smoke-color: #8b949e;
  --white-color: #e5e5e5;
  --gradient-color: linear-gradient(99.51deg, #cbbee7 9.03%, #ffffff 89.53%);

  --white-gradient-color: linear-gradient(
    99.51deg,
    #acb4fe 9.03%,
    #ae88fe 89.53%
  );*/
.white-mode .header {
  background-color: #a2a7e6; /* Byt ut med önskad färgkod */
  color: var(--black-color);
}


.white-mode .header div,
.white-mode .header p,
.white-mode .header h5,
.white-mode .header h3 {
  color: var(--black-color);
}

.white-mode .header-title-box {
  background-color: var(--white-color-smoke-bg);
  color: var(--black-color);
}

.white-mode .container-icon {
  background-color: var(--white-color-smoke-bg);
}

.white-mode .mode-icon {
  color: var(--purple-dark-color);
}

.white-mode .hr {
  background-color: var(--black-color-bg);
}

.purple-text {
  font-weight: 600;
}

.white-text {
  font-weight: 500;
  color: var(--white-color);
}

.gray-text {
  color: var(--white-smoke-color);
}

.white-mode .purple-text {
  color: var(--purple-dark-color);
}

.white-mode .gray-text {
  color: var(--black-color);
}

.white-mode .white-text {
  color: var(--black-color);
}

.white-mode .project_view_body,
div {
  color: var(--white-color-bg);
}
.white-mode .p_Lang {
  color: var(--white-color-bg);
}
.white-mode .line {
  background: linear-gradient(
    180deg,
    #e1e3f9 0%,
    var(--purple-dark-color) 100%
  );
}

.loader-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -999;
}

.loader {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: relative;
  border: 4px solid #4facfe;
  animation: loader 2s infinite ease;
}
.loader-inner {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  background-image: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);
  animation: loader-inner 2s infinite ease-in;
}
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes loader-inner {
  0% {
    height: 0%;
  }
  25% {
    height: 0%;
  }
  50% {
    height: 100%;
  }
  75% {
    height: 100%;
  }
  100% {
    height: 0%;
  }
}
.area {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100vh;
  z-index: -999;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(76, 117, 242, 0.06174509803921569);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 80px;
  height: 80px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 90px;
  height: 90px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}
