/*:root {
  /* Dark Mode */
/* --black-color-bg: #0d1117;
  --gray-color: #161b22;
  --purple-color: #acb4fe;
  --purple-smoke-color: #cbbee7;
  --white-smoke-color: #8b949e;
  --white-color: #e5e5e5;
  --gradient-color: linear-gradient(99.51deg, #cbbee7 9.03%, #ffffff 89.53%); */

/* Light Mode */
/*--white-color-bg: #dfe0ec;
  --white-color-smoke-bg: #dfe0eb;
  --purple-dark-color: #ae88fe;
  --black-color: #212121;
  --background-color:black;
  --white-gradient-color: linear-gradient(
    99.51deg,
    #acb4fe 9.03%,
    #ae88fe 89.53%
  );
}*/

.container {
  background-color: whitesmoke;
  border-radius: 15px;

}

.moha_img {
  margin-top: 10px;
  background-position: center;
  background-size: 15rem;
  background-repeat: no-repeat;
  border-radius: 15px;

}
body {
  margin: 0px 0px 50px 0px;
  background-color: #0d1117;
}
#contact {

  margin: 0px 0px 10px 0px;
}
.form-control {
  background-color: rgb(238, 238, 225);
  border-radius: 15px;
}
.contact_me {
  margin-top: 10px;
}
.img_contact {
  color: white;
  position: absolute;
  margin: 0rem 2rem;

}
/* .lookingStrong {
  
  font-weight: 700;
  font-family: "Poppins", sans-serif;
} */
.hr_contact {
  margin-left: 20px;
  border-top: 1px solid white;
  width: 90px;
  color: var(--white-smoke-color);
}
form > label {
  font-family: "Poppins", sans-serif;
}
.sending,
.noneBtn {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 12px;
  height: 56px;
  width: 190px;
  padding: 0px 22px;
  border: 0;
  border-radius: 15px;
  font-family: "Poppins", sans-serif;
  color: #f7f7f7;
  background-color: #4070f4;
  cursor: pointer;
}
button:hover {
  background-color: #4c75f2;
}
h3 {
  font-family: "Poppins", sans-serif;
}
.text {
  position: absolute;
  left: 55px;
  display: block;
  white-space: nowrap;
  font-weight: 600;
  font-size: 15px;
}
.icon {
  font-size: 25px;
}
button.sending .text {
  animation: textAnimation 5s both;
}
button.sending .icon {
  animation: iconAnimation 5s both;
}
@keyframes textAnimation {
  0%,
  30% {
    translate: 0 0;
  }
  39.9% {
    translate: 0 100px;
  }
  40% {
    translate: 300px 100px;
  }
  40.1% {
    translate: 300px -60px;
  }
  40.2% {
    translate: 33px -60px;
  }
  45% {
    translate: 33px 0;
  }
  95% {
    translate: 33px 0;
  }
  100% {
    translate: 0 0;
  }
}
@keyframes iconAnimation {
  0%,
  5% {
    translate: 0 0;
  }
  20%,
  30% {
    translate: -250px 0;
  }
  40% {
    translate: 200px 0px;
    scale: 2;
  }
  40.1% {
    translate: 200px -60px;
    scale: 1;
  }
  40.2% {
    translate: 34px -60px;
  }
  45%,
  95% {
    translate: 34px 0;
  }
  100% {
    translate: 0 0;
  }
}

@media screen and (max-width: 1096px) {
  .img_contact {
    color: white;
    position: relative;
    display: flex;
    text-align: center;
    justify-content: center;
    justify-items: center;
    justify-self: center;
  }
}

@media screen and (max-width: 768px) {
  #contact {
    padding: 20px 20px;
  }
}
