.social-media a img {
  height: 40px;
  margin: 20px 20px 0px 0px;
  cursor: pointer;
}
@media screen and (max-width: 1096px) {
  .social-media {
    margin-top: 39px;
    display: grid;
  }
  .social-media a {
    display: flex;
    justify-content: center;
    margin: 0px 200px 0px 200px;
  }
  .social-media a img {
    height: 40px;
    margin-top: 20px;
  }
}

@media screen and (max-width: 768px) {
  .social-media {
    margin-top: 0px;
  }
  .social-media a {
    display: grid;
    justify-content: center;
    margin: 0px 100px 0px 100px;
  }
  .social-media a img {
    height: 40px;
    margin-top: 20px;
  }
}
