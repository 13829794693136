.project_button {
  display: flex;
}
.project_button h4 {
  margin-left: 50px;
  font-size: 15px;
  cursor: pointer;
  padding: 10px 15px;
  transition: 1s;
}
h1 {
  color: white;
}
.round-button {
  position: absolute;
  font-size: 4.5vh;
  top: -10px; /* Justera önskad topposition här */
  right: -1px; /* Justera önskad högerposition här */
  background-color: #0d1117;
  color: #fff; /* Ange önskad textfärg här */
  border: none;
  border-radius: 15px;
  padding: 1vh 1vw;
  cursor: pointer;
  z-index: 1; /* Se till att knappen visas ovanpå bilden */
}
.round-button:hover {
  background:  #192435;;

}
.projectContainer__title--active {
  text-align: center;
  border-bottom: 2px solid #4c75f2;
  padding: 12px 24px;
  color: white;
}
/* .projects_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  margin: 0 auto;
  border-radius: 15px;
  overflow: hidden;
  transition: 0.5s;
  
} */
.text_title_Projects {
  margin: 0rem 1rem;
}
.projects_container {
  display: grid;
  justify-content: center;
  justify-items: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 4.5rem;
  row-gap: 10rem;
  margin: 0rem 2rem;
}
.project_title {
  color: white;
  display: grid;
  justify-content: center;
  justify-items: center;
}

.project_img img {
  border-radius: 15px;
  object-fit: cover;
}

.project_width:hover {
  transform: scale(1.05);
}

@media screen and (max-width: 768px) {
  .projects_container {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 90px;
  }

  .project_box {
    margin: 0px 10px;
    box-shadow: none;
  }
  .project_box h6 {
    margin-top: 15px;
    font-size: 15px;
  }
  .project_button h4 {
    margin-left: 0px;
    padding: 10px 70px;
    font-size: 15px;
  }
  small,
  .h1_Project {
    display: flex;
    justify-content: center;
  }
}
