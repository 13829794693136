.project_views_close {
  transition: display 0.4s;
  display: none;
}

.project_view_name {
  font-weight: 600;
}
.project_view_close {
  color: red;
  cursor: pointer;
}
.project_view_button {
  display: flex;
  gap: 1.5rem;
}

.project_view_button_active {
  background: hsl(222, 100%, 95%);
  color: #4070f4;
  border-radius: 15px;
}
.project_view_button h5 {
  font-size: 14px;
  transition: 0.5s;
  cursor: pointer;
  border-radius: 15px;
  color: #4070f4;
}
.project_view_github{
  overflow-wrap: break-word;

}
.desc_heading {
  width: 130px;
}
.desc_list {
  width: 100%;
}
.project_view_screenshot {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  flex-wrap: wrap;
  overflow-y: scroll;
}
.screenshot_container {
  height: 110px;
  overflow: hidden;
  margin-left: 15px;
  transition: transform 0.6s;
}
.screenshot_container:hover {
  transform: scale(1.17);
}
.screenshot_container img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
#style-5::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

#style-5::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

#style-5::-webkit-scrollbar-thumb {
  background-color: hsl(243, 80%, 62%);
}

