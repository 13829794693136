.fullstack {
  font-size: 15px;
}
.header {
  font-family: "Poppins", sans-serif;
  height: 4.5rem;
  background: #0d1117;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  z-index: 2000;
  top: 0;
  position: sticky;
  box-shadow: 0 1px 4px rgba(223, 225, 228, 0.919);
}
.header_left > h3 {
  letter-spacing: 1px;
  padding: 10px 0px 0px 30px;
  font-size: 25px;
  color: whitesmoke;
}
.header_right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
}

.dialogDocs {
}

h5 {
  margin-left: 20px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  font-weight: 100;
  padding: 0.5rem;
  color: whitesmoke;
  font-size: 17px;
}
h5:hover {
  background: rgba(76, 117, 242, 0.06274509803921569);
  color: #4c75f2;
  padding: 0.5rem;
  border-radius: 15px;
}
.nav-active {
  background: rgba(76, 117, 242, 0.06274509803921569);
  color: #4c75f2;
  padding: 0.5rem;
  border-radius: 15px;
}
.mobile_view {
  position: fixed;
  top: 10%;
  right: -100%;
  color: #fff;
  height: 100%;
  width: 50%;
  padding: 2rem;
  transition: 0.5s;
}
.show {
  display: none;
}
.toggleButton {
  display: none;
}

.dark button {
  color: white;
}
.light button {
  color: white;
}
button {
  font-size: 2rem;
}

@media screen and (max-width: 768px) {
  .mobile_view {
    display: block;
  }
  .header_right {
    display: none;
  }
  .header_left > h3 {
    margin: 10px 0px 0px 20px;
  }

  .toggleButton > img {
    height: 2.9rem;
    margin-right: 10px;
  }

  h5 {
    margin-top: 40px;
    color: #6c757d;
  }
  .dropdown-content button {
    font-size: 20px;
  }
  .show {
    display: block;
    position: fixed;
    top: 11.4%;
    margin: 0px 10px 0px 0px;
    right: 0;
    background: #fff;
    color: #fff;
    height: 6firebase 0%;
    width: 45%;
    padding: 2rem;
    transition: 0.5s;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 15px;
  }

  .toggleButton {
    margin: 0px 5px 10px 0px;
    display: block;
    background: rgba(6, 6, 7, 0.063);
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-content: center;
    border-radius: 1px;
  }
  h5 {
    font-size: 15px;
  }
}
