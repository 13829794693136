.container-about {
  margin: 0rem 2rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  color: var(--white-smoke-color);
}

.about-left {
  max-width: 40%;
}

.hello {
  font-size: 40px;
  color: white;
}
.left-skills {
  margin: 1rem 0;
}
.myText {
  display: grid;
  gap: 7%;
}
.about-right img {
  border-radius: 100%;
  width: 100%;
  max-width: 180px;
  margin: 0 2.5rem;
  height: auto;
}
.lagom {
  color: gray;
}
.lagom:link {
  text-decoration: none;
}
.hr {
}
.skills {
  display: grid;
  gap: 0px 20px;
}
a:link {
  text-decoration: none;
}
.download-btn {
  align-items: center;
  padding: 10px 2px 10px 2px;
  overflow: hidden;
  background-color: #4070f4;
  border: 0;
  border-radius: 15px;
}

.download-btn span {
  font-style: normal;
  font-weight: 400;
  line-height: 44px;
  margin-right: 15px;
  color: aliceblue;
}

.download-btn .cloud-icon,
.download-btn span,
.download-btn,
.download-btn .check-icon {
  transition: all 0.5s ease-in-out;
}

.download-btn:hover {
  background-color: #072376;
}
.cloud-icon {
  color: black;
}
.download-btn:hover .cloud-icon {
  transform: scale(1.25);
}
span {
  font-size: 22px;
}
@media (max-width: 768px) {
  .about-left {
    padding: 20px;
    text-align: left;
    max-width: 100%;
  }

  .container-about {
    display: flex;
    justify-content: center;
  }

  .about-right img {
    margin: 0 1rem;
  }

  .download-btn {
    align-items: center;
    padding: 10px 0px 10px 0px;
    overflow: hidden;
    background-color: #4070f4;
    border: 0;
    border-radius: 15px;
  }
  .download-btn:hover {
    background-color: #072376;
  }
  .download-btn span {
    font-size: 2px;
    color: aliceblue;
  }
}
